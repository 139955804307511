export const steuerKonstanten = {
    //
    // ######### aus MANUELLER Steuerrechner
    // https://www.bundesfinanzministerium.de/Web/DE/Themen/Steuern/Steuerarten/Lohnsteuer/Programmablaufplan/programmablaufplan.html

    // Siehe Seite 9. Wird auf den Bruttolohn pauschal addiert
    ZRE4O_ZUSCHLAG: 35.99,

    // Lohnsteuerfreibetrag 2022 oder Grundfreibetrag genannt
    GFB: 9984,

    // Beitragsbemessungsgrenze Kranken- und Pflegeversicherung pro Jahr
    BBGKVPV: 58050,

    // Krankenversicherungssatz im Schnitt in Prozent
    KV_PROZENT: 14.0,

    // Krankenversicherungszusatzbeitrag im Schnitt in Prozent
    KV_ZUSATZ_PROZENT: 1.3,

    // Prozent Beitragsatz für die gesetzliche Pflegeversicherung 3,05%
    // Hälfte davon für Arbeitnehmer Arbeitnehmer spezielle Aufteilung gilt für Sachsen
    PV_SATZ_ALLGEMEIN: 0.0305,

    PV_SATZ_AN_SACHEN: 0.02025,
    // Aufschlag Pflegeversicherung für Kinderlose über 23
    ZUSATZ_KINDERLOS: 0.0025,

    // Beitragbemessungsgrenze Lohnsteuer
    BBG_WEST: 84600,
    BBG_OST: 81000,

    // Rentenversicherungssatz Arbeitnehmer bei 18.6%
    RVSATZAN: 0.093,

    // Teilbetrag Vorsorgepauschale für die Rentenversicherung
    TBSVORV: 0.88,

    // Grenzwerte für die Steuerklassen V/VI
    W1STKL5: 11480,
    W2STKL5: 29298,
    W3STKL5: 222260,

    // Solidaritätszuschlag Freigrenze für alle Steuerklassen außer 3
    SOLIZFREI: 16956,

    // Entlastungfreibetrag für Alleinerziehende (Steuerklasse 2)
    EFA: 4008,

    // Parameter 2022
    // Arbeitslosenversicherungssatz
    AV_SATZ: 0.012,

    // KINDERFREIBETRAG: Kinderfreibetrag 5460 Euro (2730 Euro je Elternteil) + Freibetrag für Betreuungs- und Erziehungs- oder Ausbildungsbedarf der Kinder in Höhe von 2928 Euro (1464 Euro je Elternteil)
    // für Steuerklasse 1-3 in Steuerklasse 4 nur die Hälfte davon
    KINDERFREIBETRAG_123: 8388,

    // Höchstbetrag der Mindestvorsorgepauschale für die Kranken- und Pflege-Pflichtversicherung in Euro
    VHB_3: 3000,
    VHB_ALLGEMEIN: 1900,

    // Maximal anrechenbare Krankenversicherungsbeiträge für privat Versicherte
    KV_MAX_SK3: 3800,
    KV_MAX_1900: 1900,

    // Lohnsteuergrenze für Soli oberhalb der Milderungszone
    SOLI_MILDERUNGSZONE_MAX: 96820,

    // Werbungskostenpauschale
    ARBEITNEHMER_PAUSCH: 1000,
    // Für Sonderausgaben gilt ein Pauschbetrag von 36 Euro für Singles und 72 Euro für Ehepaare
    SONDERAUSGABEN_PAUSCH: 36,

    // Grenzwerte für die Lohnsteuerberechnung für Steuerklasse < 5 (S. 20)
    UNTEREPROGRESSIONSZONE: 14926,
    OBEREPROGRESSIONSZONE: 58597,
    OBERGRENZE_PROPORTIONALZONE1: 277826,
    // UNTERGRENZE_PROPORTIONALZONE2 = 274613;

    ALTERS_ENTLASTUNG_GRENZE: 1977,
    ALTERS_ENLASTUNG_PROZENT: {
        1942: 0.4,
        1943: 0.384,
        1944: 0.368,
        1945: 0.352,
        1946: 0.336,
        1947: 0.32,
        1948: 0.304,
        1949: 0.288,
        1950: 0.272,
        1951: 0.256,
        1952: 0.24,
        1953: 0.224,
        1954: 0.208,
        1955: 0.192,
        1956: 0.176,
        1957: 0.16,
        1958: 0.152,
        1959: 0.144,
        1960: 0.136,
        1961: 0.128,
        1962: 0.12,
        1963: 0.112,
        1964: 0.104,
        1965: 0.096,
        1966: 0.088,
        1967: 0.08,
        1968: 0.072,
        1969: 0.064,
        1970: 0.056,
        1971: 0.048,
        1972: 0.04,
        1973: 0.032,
        1974: 0.024,
        1975: 0.016,
        1976: 0.008,
        1977: 0
    },
    ALTERS_ENLASTUNG_MAX: {
        1942: 1900,
        1943: 1824,
        1944: 1748,
        1945: 1672,
        1946: 1596,
        1947: 1520,
        1948: 1444,
        1949: 1368,
        1950: 1292,
        1951: 1216,
        1952: 1140,
        1953: 988,
        1954: 912,
        1955: 836,
        1956: 760,
        1957: 722,
        1958: 684,
        1959: 646,
        1960: 608,
        1961: 570,
        1962: 532,
        1963: 494,
        1964: 456,
        1965: 418,
        1966: 380,
        1967: 342,
        1968: 304,
        1969: 266,
        1970: 228,
        1971: 190,
        1972: 152,
        1973: 114,
        1974: 76,
        1975: 38,
        1976: 0.008,
        1977: 0
    },
    KIRCHENSTEUER_TABELLE: {
        "Baden-Württemberg": 0.08,
        Bayern: 0.08,
        "Berlin (West)": 0.09,
        "Berlin (Ost)": 0.09,
        Brandenburg: 0.09,
        Bremen: 0.09,
        Hamburg: 0.09,
        Hessen: 0.09,
        "Mecklenburg-Vorpommern": 0.09,
        Niedersachsen: 0.09,
        "Nordrhein-Westfalen": 0.09,
        "Rheinland-Pfalz": 0.09,
        Saarland: 0.09,
        "Sachsen-Anhalt": 0.09,
        Sachsen: 0.09,
        "Schleswig-Holstein": 0.09,
        Thüringen: 0.09
    }
}

// Für bAV kann maximal 8% der Beitragsbemessungsgrenze West bei der Lohnsteuer geltend gemacht werden
const MAX_BRUTTO_ERRSPARNIS_LOHNSTEUER = 0.08 * steuerKonstanten.BBG_WEST
// FÜR Für bAV kann maximal 4% der Beitragsbemessungsgrenze West bei den Sozialversicherungen geltend gemacht werden
const MAX_BRUTTO_ERRSPARNIS_SV = 0.04 * steuerKonstanten.BBG_WEST

export function getMaxAnrechenbahresBAVfLohnsteuer (bAVBeitragProJahr) {
    bAVBeitragProJahr = Object.is(bAVBeitragProJahr, undefined) ? 0 : bAVBeitragProJahr
    if (bAVBeitragProJahr > MAX_BRUTTO_ERRSPARNIS_LOHNSTEUER) {
        bAVBeitragProJahr = MAX_BRUTTO_ERRSPARNIS_LOHNSTEUER
    }
    return bAVBeitragProJahr
}

export function getMaxAnrechenbahresBAVfSozialversicherungen (bAVBeitragProJahr) {
    bAVBeitragProJahr = Object.is(bAVBeitragProJahr, undefined) ? 0 : bAVBeitragProJahr
    if (bAVBeitragProJahr > MAX_BRUTTO_ERRSPARNIS_SV) {
        bAVBeitragProJahr = MAX_BRUTTO_ERRSPARNIS_SV
    }
    return bAVBeitragProJahr
}

// Krankenversicherungssatz Arbeitnehmer auf 5 Nachkommastellen
export function getKVSatzAn () {
    const res = Math.round((steuerKonstanten.KV_PROZENT / 2 / 100 + steuerKonstanten.KV_ZUSATZ_PROZENT / 2 / 100) * 100000)
    return res / 100000
}

// Pflegeversicherungssatz Arbeitnehmer auf 5 Dezimalstellen
export function getPVSatzAn (bundesland, geburtsjahr, anzahlKinder) {
    let PVSATZ = 0
    if (istPerson23oderAelter(geburtsjahr) && anzahlKinder === 0) {
        PVSATZ = steuerKonstanten.ZUSATZ_KINDERLOS
    }
    if (bundesland === "Sachsen") {
        PVSATZ = PVSATZ + steuerKonstanten.PV_SATZ_AN_SACHEN
    } else {
        PVSATZ = PVSATZ + steuerKonstanten.PV_SATZ_ALLGEMEIN / 2
    }
    const PV_SATZ_AN = Math.round(PVSATZ * 100000)
    return PV_SATZ_AN / 100000
}

export function getOstWest (bundesland) {
    return [
        "Brandenburg",
        "Berlin (Ost)",
        "Mecklenburg-Vorpommern",
        "Sachsen",
        "Sachsen-Anhalt",
        "Thüringen"
    ].includes(bundesland)
        ? "osten"
        : "westen"
}

export function getBBGRV (bundesland) {
    const ost_west = getOstWest(bundesland)
    if (ost_west === "osten") {
        return steuerKonstanten.BBG_OST
    }
    return steuerKonstanten.BBG_WEST
}

export function berechneVersicherungsPflichtigesEinkommenRV (jahresBrutto, bundesland, bAVBeitragProJahr) {
    const anrechenbaresBAV = getMaxAnrechenbahresBAVfSozialversicherungen(bAVBeitragProJahr)
    let ZRE4VP = (jahresBrutto - anrechenbaresBAV)
    const BBGRV = getBBGRV(bundesland)
    if (ZRE4VP > BBGRV) {
        ZRE4VP = BBGRV
    }
    return ZRE4VP
}

export function berechneVersicherungsPflichtigesEinkommenKV_PV (jahresBrutto, bAVBeitragProJahr) {
    const anrechenbaresBAV = getMaxAnrechenbahresBAVfSozialversicherungen(bAVBeitragProJahr)
    let ZRE4VP = (jahresBrutto - anrechenbaresBAV)
    if (ZRE4VP > steuerKonstanten.BBGKVPV) {
        ZRE4VP = steuerKonstanten.BBGKVPV
    }
    return ZRE4VP
}

export function getVHB (steuerklasse) {
    let VHB = steuerKonstanten.VHB_ALLGEMEIN
    if (steuerklasse === 3) {
        VHB = steuerKonstanten.VHB_3
    }
    return VHB
}

export function vergleichsberechnungMindestVorsorgePauschale (angenommenesBruttoVP, gesetzlich_versichert, bundesland, geburtsJahr, anzahlKinder) {
    let VSP = 0
    const versicherungspflichtigesEinkommenKV_PV = berechneVersicherungsPflichtigesEinkommenKV_PV(angenommenesBruttoVP)
    const anteilKV = berechneBeitragZurKrankenversicherungProJahr(versicherungspflichtigesEinkommenKV_PV)
    const anteilPV = berechneBeitragZurPflegeversicherungProJahr(versicherungspflichtigesEinkommenKV_PV, bundesland, geburtsJahr, anzahlKinder)
    if (gesetzlich_versichert) {
        VSP = anteilKV + anteilPV
    }
    return VSP
}

export function berechnung_vorsorge_pauschale (jahresBrutto, gesetzlich_versichert, bundesland, steuerklasse, geburtsJahr, anzahlKinder) {
    let VSP
    const VHB = getVHB(steuerklasse)
    let ZRE4VP = jahresBrutto + steuerKonstanten.ZRE4O_ZUSCHLAG

    ZRE4VP = berechneVersicherungsPflichtigesEinkommenRV(ZRE4VP, bundesland)

    const rentenVersicherung = berechneBeitragZurRentenversicherungProJahr(ZRE4VP, bundesland)
    const VSP1 = steuerKonstanten.TBSVORV * rentenVersicherung

    let VSP2 = 0.12 * ZRE4VP

    if (VSP2 > VHB) {
        VSP2 = VHB
    }

    const VSPN = Math.ceil(VSP1 + VSP2)

    // Seite 15
    VSP = Math.ceil(vergleichsberechnungMindestVorsorgePauschale(ZRE4VP, gesetzlich_versichert, bundesland, geburtsJahr, anzahlKinder) + VSP1)

    if (VSPN > VSP) {
        VSP = VSPN
    }
    return VSP
}

export function berechnung_kinderfreibetrag (steuerklasse, anzahlKinder) {
    let KINDERFREIBETRAG = 0
    if (steuerklasse < 5) {
        KINDERFREIBETRAG = steuerklasse < 4
            ? anzahlKinder * steuerKonstanten.KINDERFREIBETRAG_123
            // bei Steuerklasse 4 nur halben Kinderfreibetrag
            : anzahlKinder * steuerKonstanten.KINDERFREIBETRAG_123 * 0.5
    }
    return KINDERFREIBETRAG
}

export function berechnung_tabellen_freibeträge (steuerklasse) {
    let TABELLENFREIBETRAEGE = 0
    if (steuerklasse < 6) {
        TABELLENFREIBETRAEGE =
            steuerKonstanten.ARBEITNEHMER_PAUSCH +
            steuerKonstanten.SONDERAUSGABEN_PAUSCH
        if (steuerklasse === 2) {
            TABELLENFREIBETRAEGE += steuerKonstanten.EFA
        }
    }
    return TABELLENFREIBETRAEGE
}

// Berechnung des zu versteuernden Einkommens pro Jahr
export function berechneZVEInEuro (jahresBrutto, gesetzlich_versichert, steuerklasse, bundesland, anzahlKinder, geburtsjahr, steuerfreibetrag) {
    let ZVE = 0
    let vSteuerfreibetrag
    if (typeof steuerfreibetrag === "number") {
        vSteuerfreibetrag = steuerfreibetrag
    } else {
        vSteuerfreibetrag = 0
    }
    const vorsorgePauschale = berechnung_vorsorge_pauschale(jahresBrutto, gesetzlich_versichert, bundesland, steuerklasse, geburtsjahr, anzahlKinder)
    const kinderFreibeträge = berechnung_kinderfreibetrag(steuerklasse, anzahlKinder)
    const tabellen_freibetraege = berechnung_tabellen_freibeträge(steuerklasse)
    const altersenlastungsBetrag = berechneAltersentlastungsBetrag(geburtsjahr, jahresBrutto)

    const summeAllerAbzuege = vorsorgePauschale + kinderFreibeträge + tabellen_freibetraege + altersenlastungsBetrag + vSteuerfreibetrag

    ZVE = jahresBrutto + steuerKonstanten.ZRE4O_ZUSCHLAG - summeAllerAbzuege

    // In der Lohnsteuerübersicht wird dieser Wert mit X referenziert.
    return ZVE
}

function berechneLohnsteuerFuerSteuerklasseKleiner5 (X, steuerklasse) {
    const KZTAB = steuerklasse === 3 ? 2 : 1
    let ST = 0
    // Steuerklasse 1 - 4
    // Seite 20
    if (X <= steuerKonstanten.GFB) {
        ST = 0
    } else if (X <= steuerKonstanten.UNTEREPROGRESSIONSZONE) {
        const y = (X - steuerKonstanten.GFB) / 10000
        ST = Math.floor((1008.70 * y + 1400) * y)
    } else if (X < steuerKonstanten.OBEREPROGRESSIONSZONE) {
        const z = (X - steuerKonstanten.UNTEREPROGRESSIONSZONE) / 10000
        ST = Math.floor((206.43 * z + 2397) * z + 938.24)
    } else if (X < steuerKonstanten.OBERGRENZE_PROPORTIONALZONE1) {
        ST = Math.floor(0.42 * X - 9267.53)
    } else {
        ST = Math.floor(0.45 * X - 17602.28)
    }
    ST = ST * KZTAB
    return ST
}

// S.16 Steuerberechnung für Steuerklasse 5,6
function berechneLohnsteuerFuerSteuerklasse5und6 (X, steuerklasse) {
    let ST = 0
    const ZZX = X
    let ZX
    if (ZZX > steuerKonstanten.W2STKL5) {
        ZX = steuerKonstanten.W2STKL5
        ST = UP5_6(ZX, steuerklasse)
        if (ZZX > steuerKonstanten.W3STKL5) {
            ST = ST + (steuerKonstanten.W3STKL5 - steuerKonstanten.W2STKL5) * 0.42
            ST = Math.floor(ST + (ZZX - steuerKonstanten.W3STKL5) * 0.45)
        } else {
            ST = Math.floor(ST + (ZZX - steuerKonstanten.W2STKL5) * 0.42)
        }
    } else {
        ZX = ZZX
        ST = UP5_6(ZX, steuerklasse)
        if (ZZX > steuerKonstanten.W1STKL5) {
            const VERGL = ST
            ZX = steuerKonstanten.W1STKL5
            ST = UP5_6(ZX, steuerklasse)
            const HOCH = Math.floor(ST + (ZZX - steuerKonstanten.W1STKL5) * 0.42)
            if (HOCH < VERGL) {
                ST = HOCH
            } else {
                ST = VERGL
            }
        } else {
            ST = Math.floor(ST)
        }
    }
    return ST
}

// S.17 Hilfsfunktion für Steuerberechnung f. Steuerklasse 5,6
function UP5_6 (ZX, steuerklasse) {
    let ST = 0
    const X1 = ZX * 1.25
    const ST1 = berechneLohnsteuerFuerSteuerklasseKleiner5(X1, steuerklasse)
    const X2 = ZX * 0.75
    const ST2 = berechneLohnsteuerFuerSteuerklasseKleiner5(X2, steuerklasse)
    const DIFF = Math.floor((ST1 - ST2) * 2)
    const MIST = ZX * 0.14
    if (MIST > DIFF) {
        ST = MIST
    } else {
        ST = DIFF
    }
    return ST
}

export function berechneAltersentlastungsBetrag (geburtsjahr, jahresBrutto) {
    let altersenlastungsBetrag = 0
    if (geburtsjahr <= steuerKonstanten.ALTERS_ENTLASTUNG_GRENZE) {
        const prozentualerAltersentlastungsbetrag = jahresBrutto * steuerKonstanten.ALTERS_ENLASTUNG_PROZENT[geburtsjahr]
        const maximalerAltersentlastungsbetrag = steuerKonstanten.ALTERS_ENLASTUNG_MAX[geburtsjahr]
        if (prozentualerAltersentlastungsbetrag > maximalerAltersentlastungsbetrag) {
            altersenlastungsBetrag = maximalerAltersentlastungsbetrag
        } else {
            altersenlastungsBetrag = prozentualerAltersentlastungsbetrag
        }
    }
    return altersenlastungsBetrag
}

// Berechnung der Lohnsteuer nach Steuerklasse
// ZVE: zu versteuerndes Jahreseinkommen in Euro
export function berechneJahresLohnsteuer (ZVE, steuerklasse) {
    let ST = 0
    let X = 0
    const KZTAB = steuerklasse === 3 ? 2 : 1

    // S.13 Ermittlung der Jahreslohnsteuer
    if (ZVE < 1) {
        X = 0
    } else {
        X = Math.floor(ZVE / KZTAB)
    }

    if (steuerklasse < 5) {
        // Steuerklass 1-4
        // S. 20
        ST = berechneLohnsteuerFuerSteuerklasseKleiner5(X, steuerklasse)
    }
        // Steuerklasse 5,6
    // S.16
    else {
        ST = berechneLohnsteuerFuerSteuerklasse5und6(X, steuerklasse)
    }
    return ST
}

export function berechneBeitragZurKrankenversicherungProJahr (versicherungspflichtigesEinkommen) {
    const KVSATZAN = getKVSatzAn()
    const KVAnteilInCent = Math.round(versicherungspflichtigesEinkommen * KVSATZAN * 100)
    return KVAnteilInCent / 100
}

export function berechneBeitragZurPflegeversicherungProJahr (versicherungspflichtigesEinkommen, bundesland, geburtsJahr, anzahlKinder) {
    const PVSATZAN = getPVSatzAn(bundesland, geburtsJahr, anzahlKinder)
    const PVAnteilInCent = Math.round(versicherungspflichtigesEinkommen * PVSATZAN * 100)
    return PVAnteilInCent / 100
}

export function berechneBeitragZurRentenversicherungProJahr (versicherungspflichtigesEinkommen) {
    const RVAnteilInCent = Math.round(versicherungspflichtigesEinkommen * steuerKonstanten.RVSATZAN * 100)
    return RVAnteilInCent / 100
}

export function berechneBeitragZurArbeitslosenVersicherungProJahr (versicherungspflichtigesEinkommen) {
    const arbeitslosenVersicherungInCent = Math.round(versicherungspflichtigesEinkommen * steuerKonstanten.AV_SATZ * 100)
    return arbeitslosenVersicherungInCent / 100
}

export function berechneLohnsteuerProJahr (jahresBrutto, gesetzlich_versichert, steuerklasse, bundesland, anzahlKinder, geburtsjahr, steuerfreibetrag) {
    const ZVE = berechneZVEInEuro(jahresBrutto, gesetzlich_versichert, steuerklasse, bundesland, anzahlKinder, geburtsjahr, steuerfreibetrag)
    return berechneJahresLohnsteuer(ZVE, steuerklasse)
}

export function berechneSolidaritaetszuschlagProJahr (jahresLohnsteuer, steuerklasse) {
    const KZTAB = steuerklasse === 3 ? 2 : 1
    const SOLIZFREI = KZTAB * steuerKonstanten.SOLIZFREI
    if (jahresLohnsteuer > SOLIZFREI) {
        let SOLZJ = jahresLohnsteuer * 5.5 / 100
        const SOLZMIN = (jahresLohnsteuer - steuerKonstanten.SOLIZFREI) * 11.9 / 100
        if (SOLZMIN < SOLZJ) {
            SOLZJ = SOLZMIN
        }
        const inCent = Math.floor(SOLZJ * 100)
        return inCent / 100
    } else {
        return 0
    }
}

export function berechneKirchensteuerProJahr (jahresLohnsteuer, bundesland) {
    // Kappung nicht mit einberechnet
    const kirchensteuerProJahr = jahresLohnsteuer * steuerKonstanten.KIRCHENSTEUER_TABELLE[bundesland]
    const kirchensteuerInCent = Math.round(kirchensteuerProJahr * 100)
    return kirchensteuerInCent / 100
}

export function istPerson23oderAelter (geburtsjahr) {
    const dateNow = new Date().getFullYear()
    return dateNow - geburtsjahr >= 23
}

export function berechneNettoLohnProJahr (jahresBrutto, gesetzlich_versichert,
    steuerklasse,
    bundesland,
    kinder,
    geburt_jahr,
    steuerfreibetrag, kirchensteuer) {
    const jahresLohnsteuer = berechneLohnsteuerProJahr(jahresBrutto,
        gesetzlich_versichert,
        steuerklasse,
        bundesland,
        kinder,
        geburt_jahr,
        steuerfreibetrag)

    const versicherungspflichtigesEinkommenRV = berechneVersicherungsPflichtigesEinkommenRV(jahresBrutto, bundesland)
    const versicherungsPflichtigesEinkommenKV_PV = berechneVersicherungsPflichtigesEinkommenKV_PV(jahresBrutto)
    const solidaritaetszuschlag = berechneSolidaritaetszuschlagProJahr(jahresLohnsteuer, steuerklasse)
    let kirchensteuerBetrag = 0
    if (kirchensteuer) {
        kirchensteuerBetrag = berechneKirchensteuerProJahr(jahresLohnsteuer, bundesland)
    }
    const rentenversicherungProJahr = berechneBeitragZurRentenversicherungProJahr(versicherungspflichtigesEinkommenRV, bundesland)
    const krankenversicherungProJahr = berechneBeitragZurKrankenversicherungProJahr(versicherungsPflichtigesEinkommenKV_PV)
    const pflegeversicherungProJahr = berechneBeitragZurPflegeversicherungProJahr(
        versicherungsPflichtigesEinkommenKV_PV,
        bundesland,
        geburt_jahr,
        kinder)
    const arbeitslosenVersicherungProJahr = berechneBeitragZurArbeitslosenVersicherungProJahr(versicherungspflichtigesEinkommenRV, bundesland)

    // Jahres netto
    const netto_jahr = Math.round(
        (jahresBrutto -
            jahresLohnsteuer -
            solidaritaetszuschlag -
            kirchensteuerBetrag -
            rentenversicherungProJahr -
            krankenversicherungProJahr -
            pflegeversicherungProJahr -
            arbeitslosenVersicherungProJahr) * 100) / 100
    return netto_jahr
}
