<template>
  <section class="section">
    <div class="container is-medium">
      <div class="columns is-3 is-multiline step-container">
        <div class="column is-12-touch is-8-desktop">
          <div class="part-left">
            <div class="container">
              <div class="is-relative subline">
                <Arrow/>                
                <span v-html="returnHtml(txtString.general.headline) || ''">
                  <!-- Headline general -->
                </span>
              </div>
            </div>
            <div v-if="getState.aktives_ziel === 'auszeit'" class="container">
              <Slider :setup="slider11" ref="slider" 
              :key="getState.keychange + 'slide11'"/>              
              <div v-html="returnHtml(txtString.step2.left.slider1) || ''" class="description_slider">                
              <!-- Auszeit in Jahren -->
              </div>
            </div>
            <div v-if="getState.aktives_ziel === 'teilzeit'" class="container">
              <Slider
                :setup="slider31"
                ref="slider"
                
              />              
              <div v-html="returnHtml(txtString.step2.left.slider2) || ''" class="description_slider">
              <!-- Teilzeit in Jahren -->
              </div>
            </div>
            <div v-if="getState.aktives_ziel === 'teilzeit'" class="container">
              <Slider
                :setup="slider33"
                ref="slider"
                :key="getState.keychange + 'slide33'"
              />
              <div v-html="returnHtml(txtString.step2.left.slider3) || ''" class="description_slider">
              <!-- Teilzeit Stunden Monat -->
              </div>
             
            </div>
            <div v-if="getState.aktives_ziel === 'auszeit'" class="container">
              <Slider :setup="slider12" ref="slider" 
              :key="getState.keychange + 'slide12'"/>
              <div v-html="returnHtml(txtString.step2.left.slider4) || ''" class="description_slider">               
              <!-- Auszeit Dauer -->
              </div>
              
            </div>
            <div v-if="getState.aktives_ziel === 'teilzeit'" class="container">
              <Slider
                :setup="slider32"
                ref="slider"
                :key="getState.keychange + 'slide32'"
              />
              <div v-html="returnHtml(txtString.step2.left.slider5) || ''" class="description_slider">
              <!-- Teilzeit Dauer -->
              </div>
              
            </div>
            <div v-if="getState.aktives_ziel === 'rente'" class="container">
              <Slider
                :setup="slider21"
                ref="slider"                
              />
              <div v-html="returnHtml(txtString.step2.left.slider6) || ''" class="description_slider">
               <!-- Alter rentennahe Auszeit -->
              </div>
              
            </div>
            <div v-if="getState.aktives_ziel === 'rente' || getState.aktives_ziel === 'auszeit' || getState.aktives_ziel === 'teilzeit'" class="container">
              <Slider :setup="slider61" ref="slider" v-if="getState.aktives_ziel === 'rente' || getState.aktives_ziel === 'auszeit' || getState.aktives_ziel === 'teilzeit'" 
              :key="getState.keychange + 'slide61'"/>
              <div v-html="returnHtml(txtString.step2.left.slider7) || ''" class="description_slider">
               <!-- Gehalt in % -->
              </div>              
            </div>
             <div v-if="getState.aktives_ziel === 'geldImAlter'" class="container">
              <Slider :setup="slider41" ref="slider" :key="getState.keychange + 'slide41'"/>
              <div v-html="returnHtml(txtString.step2.left.slider8) || ''" class="description_slider">
                <!-- Alter Rente -->
              </div>
             
            </div>
             <div v-if="getState.aktives_ziel === 'geldImAlter'" class="container">
              <Slider :setup="slider42" ref="slider" :key="getState.keychange + 'slide42'"/>
              <div v-html="returnHtml(txtString.step2.left.slider9) || ''" class="description_slider">                
              <!-- Zusatzrente € -->
              </div>             
            </div>
            <div class="level is-mobile">
              <div
              v-html="returnHtml(txtString.general.button.back) || ''"
               class="level-left button back" v-on:click="navigate(1)">
                <!-- Button Zurück -->
              </div>
              <div 
              v-html="returnHtml(txtString.general.button.forward) || ''"
              class="level-right button forward" v-on:click="navigate(3)">
                <!-- Button Weiter -->
              </div>
            </div>
          </div>
        </div>
        <div class="column is-12-touch is-4-desktop">
          <div class="part-right">
            <div class="is-relative">
               <ArrowBlack />
              <span v-html="returnHtml(txtString.step2.right.headline) || ''">
                <!-- Headline right -->
              </span>
              <p v-html="returnHtml(txtString.step2.right.text) || ''">
                <!-- Text right -->
              </p>
            
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import Slider from "../components/Slider.vue";
import Arrow from "../svg/Arrow.vue";
import ArrowBlack from "../svg/ArrowBlack.vue";
import { mapState } from "vuex";
export default {
  name: "Step2",
  components: {
    Slider,
    Arrow,
    ArrowBlack
  },
  data: () => {
    return {
      slider11: {
        min: 1,
        max: 40,
        step: 1,
        // id_tag: "wunsch_rentenalter"
        id_tag: "auszeit_in_jahre",
      },
      slider12: {
        min: 1,
        max: 12,
        step: 1,
        id_tag: "auszeit_monate",
      },
      slider61: {
        min: 70,
        max: 130,
        step: 10,
        id_tag: "gehalt_prozent",
        unit: "%",
      },
      slider31: {
        min: 1,
        max: 5,
        step: 1,
        id_tag: "teilzeit_in_jahre",
      },
      slider32: {
        min: 1,
        max: 48,
        step: 1,
        id_tag: "teilzeit_dauer",
      },
      slider33: {
        min: 10,
        max: 39,
        step: 1,
        id_tag: "teilzeit_stunden",
      },
      slider21: {
        min: 50,
        max: 66,
        step: 1,
        id_tag: "wunsch_rentenalter",
      },
        slider41: {
        min: 63,
        max: 70,
        step: 1,
        id_tag: "gia_alter",
      },
       slider42: {
        min: 100,
        max: 6000,
        step: 100,
        unit: "€",
        id_tag: "zusatzrente",
      },
      anrede: null,
    };
  },
  methods: {
    onchange: function (target) {
   
    },
    navigate: function (direction) {
      this.$emit("navigate", direction);
      document.getElementById("app").scrollIntoView();
    },
    
  },
  computed: {
    getState() {
      return this.$store.getters.getState;
    },  
  },
  created() {
    
  },
};
</script>
<style scoped lang="scss">
.section {
  padding: 0;
}
</style>
