<template>
  <div class="select">
    <select
    :style="style_select"
      class="has-text-centered"
      v-bind:id="select_id"
      v-on:input="onChange($event)"
    >
      <option
        v-for="m in select_options"
        :value="m"
        :key="m"
        :selected="m == getState[select_id] ? 'seleceted' : ''"
      >
        {{ m }}
      </option>
    </select>
  </div>
</template>

<script>
import { bus } from "../main";
import { mapState } from "vuex";
export default {
  name: "Select",
  props: ["setup", "options"],
  data: function() {
    return {
      // slider_value: this.setup.start,
      select_id: this.setup.id_tag,
      select_options: this.options,
    };
  },
  methods: {
    onChange: function(event) {
      console.log("SELECT ID: "+event.target.id)
      this.$store.commit("change_value", {
        id: event.target.id,
        value: event.target.value,
      });
    },
  },
  computed: {
    getState() {
      return this.$store.getters.getState;
    },
    style_select(){
      return ''
    }
  },

  mounted() {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
