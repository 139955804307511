// import Velocity from "velocity-animate"; use this for complex animation
import { Navigation } from '../js-components/navigation';
import { Accordion } from '../js-components/accordion';
import { Collapsible } from "../js-components/collapsible";
import { Animations } from '../js-components/animations';
import { Distancetop } from '../js-components/distancetop';
import { CircleFirefox } from '../js-components/circleFirefox';

export default {

  init() {
    // JavaScript to be fired on all pages
    // Distancetop();
    Animations();
    Navigation();
    CircleFirefox();
    //Accordion();
    //Collapsible();
  }
}
