<template>
  <div id="pdf-wrap">
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="download"
      :preview-modal="modal"
      filename="Zusammenfassung"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      ref="html2Pdf"
      @hasDownloaded="scrollDown()"
    >
      <section id="pdf-dl" slot="pdf-content">
        <!-- PDF Content Here -->

        <section id="page-one" class="pdf page-one">
          <PdfDownloadHeader />
          <section class="section result has-background-light">
            <div class="container is-small">
              <div class="columns is-mobile is-centered">
                <div class="column is-9">
                  <img class="pfeil" :src="arrow" alt="" />
                  <p class="is-uppercase is-size-4">
                    <span v-html="returnHtml(txtString.pdf.headline) || ''">
                      <!-- Headline -->
                    </span>
                    <span v-if="getState.aktives_ziel === 'rente'">
                      <strong
                        v-html="
                          returnHtml(
                            txtString.general.calculators.prePension
                          ) || ''
                        "
                      >
                        <!-- Rentennahe Auszeit -->
                      </strong>
                    </span>
                    <span v-if="getState.aktives_ziel === 'auszeit'">
                      <strong
                        v-html="
                          returnHtml(txtString.general.calculators.timeout) ||
                          ''
                        "
                      >
                        <!-- Auszeit -->
                      </strong>
                    </span>
                    <span v-if="getState.aktives_ziel === 'teilzeit'">
                      <strong
                        v-html="
                          returnHtml(txtString.general.calculators.parttime) ||
                          ''
                        "
                      >
                        <!-- Teilzeit -->
                      </strong>
                    </span>
                    <span v-if="getState.aktives_ziel === 'geldImAlter'">
                      <strong
                        v-html="
                          returnHtml(txtString.general.calculators.pension) ||
                          ''
                        "
                      >
                        <!-- Geld im Alter -->
                      </strong>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section class="data">
            <p
              class="is-uppercase my-4"
              v-html="returnHtml(txtString.pdf.description) || ''"
            >
              <!-- Beschreibung -->
            </p>

            <h2 class="is-uppercase">
              <strong v-html="returnHtml(txtString.pdf.headlineBaseData) || ''">
                <!-- Stammdaten -->
              </strong>
            </h2>
            <div class="columns is-mobile is-multiline">
              <div class="column is-8">
                <p v-html="returnHtml(txtString.pdf.columns.born) || ''">
                  <!-- Geboren am -->
                </p>
              </div>
              <div class="column is-4">
                <p>{{ getState.geburt_monat }} {{ getState.geburt_jahr }}</p>
              </div>
              <div class="column is-8">
                <p v-html="returnHtml(txtString.pdf.columns.hours) || ''">
                  <!-- Wochenstunden -->
                </p>
              </div>
              <div class="column is-4">
                <p>{{ getState.wochenstunden }}</p>
              </div>
              <div class="column is-8">
                <p v-html="returnHtml(txtString.pdf.columns.gSalary) || ''">
                  <!-- Brutto-Gehalt im Monat -->
                </p>
              </div>
              <div class="column is-4">
                <p>{{ addCommas(getState.bruttogehalt_monat) }} €</p>
              </div>
              <div class="column is-8">
                <p v-html="returnHtml(txtString.pdf.columns.taxClass) || ''">
                  <!-- Steuerklasse -->
                </p>
              </div>
              <div class="column is-4">
                <p>{{ getState.steuerklasse }}</p>
              </div>
              <div class="column is-8">
                <p v-html="returnHtml(txtString.pdf.columns.state) || ''">
                  <!-- Bundesland -->
                </p>
              </div>
              <div class="column is-4">
                <p>{{ getState.bundesland }}</p>
              </div>
              <div class="column is-8">
                <p v-html="returnHtml(txtString.pdf.columns.curchTax) || ''">
                  <!-- Kirchensteuer -->
                </p>
              </div>
              <div class="column is-4">
                <p>{{ getState.kirchensteuer_go }}</p>
              </div>
              <div class="column is-8">
                <p
                  v-html="returnHtml(txtString.pdf.columns.insurance.txt) || ''"
                >
                  <!-- Krankenversicherung -->
                </p>
              </div>
              <div class="column is-4">
                <p
                  v-if="getState.gesetzlich_versichert"
                  v-html="
                    returnHtml(txtString.pdf.columns.insurance.statutory) || ''
                  "
                >
                  <!-- Gesetzlich -->
                </p>
                <p
                  v-else
                  v-html="
                    returnHtml(txtString.pdf.columns.insurance.private) || ''
                  "
                >
                  <!-- Privat -->
                </p>
              </div>
            </div>
          </section>
          <PdfDownloadFooter />
          <div class="pagecount is-size-7">Seite 1 von 4</div>
        </section>
        <section class="pdf pages">
          <PdfDownloadHeader />
          <section class="data">
            <h2 class="is-uppercase">
              <strong v-html="returnHtml(txtString.pdf.headlineAdoption) || ''">
                <!-- Headline - Adoption -->
              </strong>
            </h2>
            <div class="columns is-mobile is-multiline">
              <div class="column is-8">
                <p v-html="returnHtml(txtString.pdf.columns.credit) || ''">
                  <!-- Vorhandenes Guthaben -->
                </p>
              </div>
              <div class="column is-4">
                <p>{{ addCommas(getState.vorhandenes_guthaben) }} €</p>
              </div>
              <div class="column is-8" v-if="getState.theme.options.arbeitgeberzuschuss">
                <p v-html="returnHtml(txtString.pdf.columns.employerSub) || ''">
                  <!-- Arbeitgeberzuschuss pro Monat -->
                </p>
              </div>
              <div class="column is-4" v-if="getState.theme.options.arbeitgeberzuschuss">
                <p>{{ addCommas(getState.zuschuss_arbeitgeber) }} €</p>
              </div>
              <div class="column is-8">
                <p v-html="returnHtml(txtString.pdf.columns.return) || ''">
                  <!-- Renditeerwartung p. a. -->
                </p>
              </div>
              <div class="column is-4">
                <p>{{ getState.rendite }} %</p>
              </div>
              <div class="column is-8">
                <p v-html="returnHtml(txtString.pdf.columns.salaryTrend) || ''">
                  <!-- Gehaltstrend p. a. -->
                </p>
              </div>
              <div class="column is-4">
                <p>{{ getState.gehaltssteigerung }} %</p>
              </div>
              <div v-if="getState.aktives_ziel === 'geldImAlter'" class="column is-8">
                <p v-html="returnHtml(txtString.pdf.columns.startParti) || ''">
                  <!-- Teilnahmebeginn -->
                </p>
              </div>
              <div v-if="getState.aktives_ziel === 'geldImAlter'" class="column is-4">
                <p>
                  {{ getState.teilnahme_monat }} {{ getState.teilnahme_jahr }}
                </p>
              </div>
              <div  v-if="getState.aktives_ziel === 'teilzeit'" class="column is-8">
                <p v-html="returnHtml(txtString.pdf.columns.partTimeWorkload) || ''">
                  <!-- Teilzeit Stunden -->
                </p>
              </div>
              <div  v-if="getState.aktives_ziel === 'teilzeit'" class="column is-4">
                <p>
                  {{ getState.teilzeit_stunden }}
                </p>
              </div>
            </div>
          </section>
          <PdfDownloadFooter />
          <div class="pagecount is-size-7">Seite 2 von 4</div>
        </section>
        <section class="pdf pages">
          <PdfDownloadHeader />
          <section class="data">
            <h2 class="is-uppercase">
              <strong v-html="returnHtml(txtString.pdf.headlineResult) || ''">
                <!-- Headline - Result -->
              </strong>
            </h2>
            <div class="columns is-mobile is-multiline">
              <div
                v-if="
                  getState.theme.options.urlaub &&
                  getState.aktives_ziel !== 'geldImAlter'
                "
                class="column is-8"
              >
                <p
                  v-html="returnHtml(txtString.pdf.columns.vacationYear) || ''"
                >
                  <!-- Urlaubstage (im Jahr) -->
                </p>
              </div>
              <div
                v-if="
                  getState.theme.options.urlaub &&
                  getState.aktives_ziel !== 'geldImAlter'
                "
                class="column is-4"
              >
                <p v-if="getState.urlaub_jahr === 1">
                  {{ getState.urlaub_jahr }}
                  <span
                    v-html="returnHtml(txtString.pdf.units.day.single) || ''"
                  >
                    <!-- Tag -->
                  </span>
                </p>
                <p v-else>
                  {{ getState.urlaub_jahr }}
                  <span
                    v-html="returnHtml(txtString.pdf.units.day.multi) || ''"
                  >
                    <!-- Tage -->
                  </span>
                </p>
              </div>
              <div
                v-if="
                  getState.theme.options.ueberstunden &&
                  getState.aktives_ziel !== 'geldImAlter'
                "
                class="column is-8"
              >
                <p
                  v-html="returnHtml(txtString.pdf.columns.overtimeMonth) || ''"
                >
                  <!-- Überstunden (im Monat) -->
                </p>
              </div>
              <div
                v-if="
                  getState.theme.options.ueberstunden &&
                  getState.aktives_ziel !== 'geldImAlter'
                "
                class="column is-4"
              >
                <p v-if="getState.ueberstunden_monat === 1">
                  {{
                    getState.ueberstunden_monat +
                    " " +
                    txtString.pdf.units.hour.single
                  }}
                </p>
                <p v-else>
                  {{
                    getState.ueberstunden_monat +
                    " " +
                    txtString.pdf.units.hour.multi
                  }}
                </p>
              </div>
              <div
                v-if="getState.theme.options.sparen_monat"
                class="column is-8"
              >
                <p v-html="returnHtml(txtString.pdf.columns.saveMonth) || ''">
                  <!-- Monatsgehalt brutto sparen (netto zahlen) -->
                </p>
              </div>
              <div
                v-if="getState.theme.options.sparen_monat"
                class="column is-4"
              >
                <p>
                  {{ addCommas(getState.sparen_gehalt) }} € ({{
                    addCommas(getState.netto_zahlen_monat)
                  }}
                  €)
                </p>
              </div>
              <div
                v-if="getState.theme.options.sparen_jahr"
                class="column is-8"
              >
                <p v-html="returnHtml(txtString.pdf.columns.saveYear) || ''">
                  <!-- Sonderzahlungen im Jahr brutto sparen (netto zahlen) -->
                </p>
              </div>
              <div
                v-if="getState.theme.options.sparen_jahr"
                class="column is-4"
              >
                <p>
                  {{ addCommas(getState.sparen_jahr) }} € ({{
                    addCommas(getState.netto_zahlen_sonderzahlungJahr)
                  }}
                  €)
                </p>
              </div>
              <div class="column is-8">
                <p class="empty"></p>
              </div>
              <div class="column is-4">
                <p class="empty"></p>
              </div>
              <div class="column is-8">
                <p
                  v-if="getState.aktives_ziel === 'rente'"
                  v-html="
                    returnHtml(txtString.pdf.columns.prePension.start) || ''
                  "
                >
                  <!-- Beginn des Vorruhestands -->
                </p>
                <p
                  v-if="getState.aktives_ziel === 'auszeit'"
                  v-html="returnHtml(txtString.pdf.columns.timeOut.start) || ''"
                >
                  <!-- Beginn der Auszeit -->
                </p>
                <p
                  v-if="getState.aktives_ziel === 'teilzeit'"
                  v-html="returnHtml(txtString.pdf.columns.timeOut.start) || ''"
                >
                  <!-- Beginn der Teilzeit -->
                </p>
                <p
                  v-if="getState.aktives_ziel === 'geldImAlter'"
                  v-html="returnHtml(txtString.pdf.columns.pension.start) || ''"
                >
                  <!-- Beginn der Rente -->
                </p>
              </div>
              <div class="column is-4">
                <p v-if="getState.aktives_ziel === 'rente'">
                  {{ getState.einstiegs_monat_fuer_rente }} {{ getState.renteneintritt_jahr }}
                </p>
                <p v-if="getState.aktives_ziel === 'auszeit'">
                  {{ getState.monat }}
                  {{ getState.jahr + getState.auszeit_in_jahre }}
                </p>
                <p v-if="getState.aktives_ziel === 'teilzeit'">
                  {{ getState.monat }}
                  {{ getState.jahr + getState.teilzeit_in_jahre }}
                </p>
                <p v-if="getState.aktives_ziel === 'geldImAlter'">
                  {{ getState.einstiegs_monat_fuer_rente }}
                  {{
                    parseInt(getState.geburt_jahr) +
                    parseInt(getState.gia_alter)
                  }}
                </p>
              </div>
              <div class="column is-8">
                <p
                  v-if="getState.aktives_ziel === 'rente'"
                  v-html="
                    returnHtml(txtString.pdf.columns.prePension.end) || ''
                  "
                >
                  <!-- Ende des Vorruhestands -->
                </p>
                <p
                  v-if="getState.aktives_ziel === 'auszeit'"
                  v-html="returnHtml(txtString.pdf.columns.timeOut.end) || ''"
                >
                  <!-- Ende der Auszeit -->
                </p>
                <p
                  v-if="getState.aktives_ziel === 'teilzeit'"
                  v-html="returnHtml(txtString.pdf.columns.partTime.end) || ''"
                >
                  <!-- Ende der Teilzeit -->
                </p>
                <p
                  v-if="getState.aktives_ziel === 'geldImAlter'"
                  v-html="returnHtml(txtString.pdf.columns.pension.end) || ''"
                >
                  <!-- Ende der Laufzeit -->
                </p>
              </div>
              <div class="column is-4">
                <p v-if="getState.aktives_ziel === 'rente'">
                  {{ getState.rentenaustritt_monat }}
                  {{ getState.rentenaustritt_jahr }}
                </p>
                <p v-if="getState.aktives_ziel === 'auszeit'">
                  {{ getState.ende_auszeit_monat }}
                  {{ getState.ende_auszeit_jahr }}
                </p>
                <p v-if="getState.aktives_ziel === 'teilzeit'">
                  {{ getState.ende_teilzeit_monat }}
                  {{ getState.ende_teilzeit_jahr }}
                </p>
                <p v-if="getState.aktives_ziel === 'geldImAlter'">
                  {{ getState.geburt_monat }}
                  {{
                    parseInt(getState.geburt_jahr) +
                    parseInt(getState.gia_alter) +
                    parseInt(
                      getState.lebenserwartung[getState.gia_alter][
                        getState.geschlecht
                      ]
                    )
                  }}
                </p>
              </div>
              <div class="column is-8">
                <p
                  v-if="getState.aktives_ziel === 'rente'"
                  v-html="
                    returnHtml(txtString.pdf.columns.prePension.payment) || ''
                  "
                >
                  <!-- Gehalt während des Vorruhestands -->
                </p>
                <p
                  v-if="getState.aktives_ziel === 'auszeit'"
                  v-html="
                    returnHtml(txtString.pdf.columns.timeOut.payment) || ''
                  "
                >
                  <!-- Gehalt während der Auszeit -->
                </p>
                <p
                  v-if="getState.aktives_ziel === 'teilzeit'"
                  v-html="
                    returnHtml(txtString.pdf.columns.partTime.payment) || ''
                  "
                >
                  <!-- Gehalt während der Teilzeit -->
                </p>
                <p
                  v-if="getState.aktives_ziel === 'geldImAlter'"
                  v-html="
                    returnHtml(txtString.pdf.columns.pension.payment) || ''
                  "
                >
                  <!-- Zusatzrente -->
                </p>
              </div>
              <div class="column is-4">
                <p v-if="getState.aktives_ziel === 'geldImAlter'">
                  {{ getState.zusatzrente }} €
                </p>
                <p v-else>
                  {{
                    addCommas(
                      Math.round(getState.bruttogehalt_monat *
                        (getState.gehalt_prozent / 100))
                    )
                  }}
                  €* / {{ getState.gehalt_prozent }} %
                </p>
              </div>
              <div v-if="getState.aktives_ziel === 'rente'" class="column is-8">
                <p
                  v-html="
                    returnHtml(txtString.pdf.columns.prePension.startLegally) ||
                    ''
                  "
                >
                  <!-- Beginn gesetzliche Rente -->
                </p>
              </div>
              <div v-if="getState.aktives_ziel === 'rente'" class="column is-4">
                <p>
                  {{ parseInt((getState.rente_einstiegsalter[getState.renteneintritt] - 1 ) / 12) + " Jahre, "}}
                    {{ (getState.rente_einstiegsalter[getState.renteneintritt] - 1 ) % 12 !== 0 ?(getState.rente_einstiegsalter[getState.renteneintritt] -
                      1) %
                    12+ " Monat(e)" : ""}}
                </p>
              </div>
              <div class="column is-8">
                <p>
                  <strong
                    v-html="
                      returnHtml(txtString.pdf.columns.total.capital) || ''
                    "
                  >
                    <!-- Gesamtkapital -->
                  </strong>
                </p>
              </div>
              <div class="column is-4">
                <p>
                  <strong
                    >{{
                      addCommas(
                        getState.details.output
                      )
                    }}
                    €*</strong
                  >
                </p>
              </div>
              <div class="column is-8">
                <p>
                  <strong
                    v-if="getState.aktives_ziel === 'rente'"
                    v-html="
                      returnHtml(txtString.pdf.columns.prePension.excess) || ''
                    "
                  >
                    <!-- Überschuss (Restkapital nach Vorruhestand) -->
                  </strong>
                  <strong
                    v-if="getState.aktives_ziel === 'auszeit'"
                    v-html="
                      returnHtml(txtString.pdf.columns.timeOut.excess) || ''
                    "
                  >
                    <!-- Überschuss (Restkapital nach Auszeit) -->
                  </strong>
                  <strong
                    v-if="getState.aktives_ziel === 'teilzeit'"
                    v-html="
                      returnHtml(txtString.pdf.columns.partTime.excess) || ''
                    "
                  >
                    <!-- Überschuss (Restkapital nach Teilzeit) -->
                  </strong>
                  <strong
                    v-if="getState.aktives_ziel === 'geldImAlter'"
                    v-html="
                      returnHtml(txtString.pdf.columns.pension.excess) || ''
                    "
                  >
                    <!-- Überschuss (Restkapital nach Ende der Laufzeit) -->
                  </strong>
                </p>
              </div>
              <div class="column is-4">
                <p>
                  <strong
                    v-if="
                      getState.aktives_ziel === 'rente' ||
                      getState.aktives_ziel === 'geldImAlter'
                    "
                    >{{ getState.ziel_prozent === 100 ? 0 :
                      addCommas( Math.round(
                        getState.kapital +
                          getState.rendite_ueberschuss_rente -
                          getState.kapital_notwendig)
                      )
                    }}
                    €</strong
                  >
                  <strong v-else
                    >{{ getState.ziel_prozent === 100 ? 0 :
                      addCommas(Math.round(getState.kapital - getState.zielkapital))
                    }}
                    €</strong
                  >
                </p>
              </div>
            </div>
          </section>
          <PdfDownloadFooter />
          <div class="pagecount is-size-7">Seite 3 von 4</div>
        </section>
        <section class="pdf pages">
          <PdfDownloadHeader />
          <section class="data">
            <h2 class="is-uppercase">
              <strong v-html="returnHtml(txtString.pdf.headlineDetails) || ''">
                <!-- Headline - Details  -->
              </strong>
            </h2>
            <div class="columns is-mobile is-multiline">
              <div
                v-if="
                  getState.theme.options.urlaub &&
                  getState.aktives_ziel !== 'geldImAlter'
                "
                class="column is-8"
              >
                <p
                  v-html="
                    returnHtml(txtString.pdf.columns.total.vacation) || ''
                  "
                >
                  <!-- Urlaubstage (im Jahr) / Summe -->
                </p>
              </div>
              <div
                v-if="
                  getState.theme.options.urlaub &&
                  getState.aktives_ziel !== 'geldImAlter'
                "
                class="column is-4"
              >
                <p v-if="getState.urlaub_jahr === 1">
                  {{
                    getState.urlaub_jahr +
                    " " +
                    txtString.pdf.units.day.single +
                    " /"
                  }}

                  {{ addCommas(getState.summe_urlaubstage) }} €
                </p>
                <p v-else>
                  {{
                    getState.urlaub_jahr +
                    " " +
                    txtString.pdf.units.day.multi +
                    " /"
                  }}
                  {{ addCommas(getState.summe_urlaubstage) }} €
                </p>
              </div>
              <div
                v-if="
                  getState.theme.options.ueberstunden &&
                  getState.aktives_ziel !== 'geldImAlter'
                "
                class="column is-8"
              >
                <p
                  v-html="
                    returnHtml(txtString.pdf.columns.total.overtime) || ''
                  "
                >
                  <!-- Überstunden (pro Monat) / Summe -->
                </p>
              </div>
              <div
                v-if="
                  getState.theme.options.ueberstunden &&
                  getState.aktives_ziel !== 'geldImAlter'
                "
                class="column is-4"
              >
                <p v-if="getState.ueberstunden_monat === 1">
                  {{
                    getState.ueberstunden_monat +
                    " " +
                    txtString.pdf.units.hour.single +
                    " / "
                  }}

                  {{ addCommas(getState.summe_ueberstunden) }} €
                </p>
                <p v-else>
                  {{
                    getState.ueberstunden_monat +
                    " " +
                    txtString.pdf.units.hour.multi +
                    " / "
                  }}
                  {{ addCommas(getState.summe_ueberstunden) }} €
                </p>
              </div>
              <div
                v-if="getState.theme.options.sparen_monat"
                class="column is-8"
              >
                <p
                  v-html="
                    returnHtml(txtString.pdf.columns.total.saveMonth) || ''
                  "
                >
                  <!-- Monatsgehalt (netto zahlen) / Summe -->
                </p>
              </div>
              <div
                v-if="getState.theme.options.sparen_monat"
                class="column is-4"
              >
                <p>
                  {{
                    addCommas(getState.netto_monat - getState.netto_sMonat) +
                    " € / "
                  }}
                  {{ addCommas(getState.summe_nMonat) }} €
                </p>
              </div>
              <div
                v-if="getState.theme.options.sparen_jahr"
                class="column is-8"
              >
                <p
                  v-html="
                    returnHtml(txtString.pdf.columns.total.saveYear) || ''
                  "
                >
                  <!-- Sonderzahlungen (pro Jahr, netto zahlen) / Summe -->
                </p>
              </div>
              <div
                v-if="getState.theme.options.sparen_jahr"
                class="column is-4"
              >
                <p>
                  {{
                    addCommas(getState.netto_jahr - getState.netto_sJahr) +
                    " € / "
                  }}
                  {{ addCommas(getState.summe_nSonderzahlung) }} €
                </p>
              </div>
              <div class="column is-8">
                <p
                  v-html="returnHtml(txtString.pdf.columns.total.return) || ''"
                >
                  <!-- Rendite -->
                </p>
              </div>
              <div class="column is-4">
                <p>
                  {{
                    addCommas(
                      getState.rendite_gewinn + getState.rendite_gewinn_rente
                    )
                  }}
                  €
                </p>
              </div>
              <div class="column is-8">
                <p v-html="returnHtml(txtString.pdf.columns.total.tax) || ''">
                  <!-- Steuer- und Sozialversicherungsersparnis -->
                </p>
              </div>
              <div class="column is-4">
                <p>{{ addCommas(getState.steuerersparnis) }} €</p>
              </div>
              <div class="column is-8" v-if="getState.theme.options.arbeitgeberzuschuss">
                <p
                  v-html="
                    returnHtml(txtString.pdf.columns.total.employerSub) || ''
                  "
                >
                  <!-- Arbeitgeberzuschuss pro Monat -->
                </p>
              </div>
              <div class="column is-4" v-if="getState.theme.options.arbeitgeberzuschuss">
                <p>{{ addCommas(getState.summe_arbeitgeber) }} €</p>
              </div>
            </div>
            <div class="details">
              <p v-html="returnHtml(txtString.pdf.summary.text) || ''">
                <!-- Summary text -->
              </p>
              <div v-if="getState.ziel_prozent >= 100">
                <p
                  v-if="getState.aktives_ziel === 'rente'"
                  v-html="returnHtml(txtString.pdf.summary.prePension) || ''"
                >
                  <!-- Summary - Vorruhestabd -->
                </p>

                <p
                  v-if="getState.aktives_ziel === 'auszeit'"
                  v-html="returnHtml(txtString.pdf.summary.timeOut) || ''"
                >
                  <!-- Summary - Auszeit  -->
                </p>

                <p
                  v-if="getState.aktives_ziel === 'teilzeit'"
                  v-html="returnHtml(txtString.pdf.summary.partTime) || ''"
                >
                  <!-- Summary - Teilzeit  -->
                </p>
                <p
                  v-if="getState.aktives_ziel === 'geldImAlter'"
                  v-html="returnHtml(txtString.pdf.summary.pension) || ''"
                >
                  <!-- Summary - Geld im Alter -->
                </p>
              </div>
            </div>
          </section>
          <PdfDownloadFooter />
          <div class="pagecount is-size-7">Seite 4 von 4</div>
        </section>

        <!-- PDF Content End -->
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
import Arrow from "../svg/Arrow.vue";
import PdfDownloadFooter from "./PdfDownloadFooter.vue";
import PdfDownloadHeader from "./PdfDownloadHeader.vue";
import { mapState } from "vuex";
import VueHtml2pdf from "vue-html2pdf";
export default {
  name: "PdfDownload",
  components: {
    VueHtml2pdf,
    Arrow,
    PdfDownloadFooter,
    PdfDownloadHeader,
  },
  data: () => {
    return {
      arrow: pdf_arrow_url,
      download: false,
      modal: true,
    };
  },
  methods: {
    generateReport() {
       var spinner= document.getElementById("spinner")
       spinner.style.display = "block";

      this.scroll = document.documentElement.scrollTop;
      window.scrollTo(0, 0);
      this.$refs.html2Pdf.generatePdf();
    },

    scrollDown() {
      window.scrollTo(this.scroll, this.scroll);
       var spinner= document.getElementById("spinner")
       spinner.style.display = "none";
    },

    hasGenerated() {
      window.scrollTo(0, this.scroll);
    },

    addCommas: function (nStr) {
      nStr += "";
      var x = nStr.split(".");
      var x1 = x[0];
      var x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "." + "$2");
      }
      return x1 + x2;
    },

    created() {
      getState.theme.anrede = anrede;
      this.logo = logo;
    },
  },

  computed: {
    getState() {
      return this.$store.getters.getState;
    },

  },
  mounted(){

      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        // true for mobile device
        this.modal=false
         this.download=true

      } else {
        // false for not mobile device
         this.modal=true
         this.download=false

        }




  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
