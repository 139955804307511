import Vue from "vue";
import App from "./calcy/App.vue";
import Auszeit from "./calcy/svg/Auszeit.vue";
import Teilzeit from "./calcy/svg/Teilzeit.vue";
import Rente from "./calcy/svg/Rente.vue";
import GeldImAlter from "./calcy/svg/GeldImAlter.vue";
import store from "./calcy/store";
import "./calcy/assets/functions/animated.js";

import $ from "jquery";
import "./style.scss";
import Router from "./util/Router";
import common from "./routes/common";
import home from "./routes/home";
import Languages from "./calcy/languages/";
import LanguagesStandard from "./calcy/languages/index.js"
import LanguagesKaercher from "./calcy/languages/kaercher.js"
Vue.config.productionTip = false;
export const bus = new Vue();

if(json_name==="kaercher"){
    Vue.mixin(LanguagesKaercher);
}
else{
    Vue.mixin(LanguagesStandard);
}



if (document.getElementById("calcy")) {
    Vue.component("Auszeit", Auszeit);
    Vue.component("Teilzeit", Teilzeit);
    Vue.component("Rente", Rente);
    Vue.component("GeldImAlter", GeldImAlter);
    new Vue({
        store,
        el: "#calcy",
        render: (h) => h(App),
    });
}

/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
    /** All pages */
    common,
    /** Home page */
    home,
    // beachte die geänderte Schreibweise:
    // beispielSeite
});

/** Load Events */
$(document).ready(() => routes.loadEvents());
