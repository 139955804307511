export const state = {
    // ????
    rente_einstiegsalter: {
        1955: 789,
        1956: 790,
        1957: 791,
        1958: 792,
        1959: 795,
        1960: 797,
        1961: 799,
        1962: 801,
        1963: 803,
        1964: 805
    },

    // aus Rechner Eingabe
    steuerklasse: 3,
    geburt_jahr: 1987,
    geburt_monat: "Oktober",
    einstiegs_monat_fuer_rente: "",
    bruttogehalt_monat: 3000,
    letztes_gehalt: null,
    bundesland: "Berlin (Ost)",
    kinder: 0,
    privat_versichert: 0,
    gesetzlich_versichert: true,
    gesetzlich_versichert_go: "Ja",
    kirchensteuer: true,
    kirchensteuer_go: "Ja",
    teilnahme_monat: null,
    teilnahme_jahr: 2020,
    vorhandenes_guthaben: 0,

    steuerfreibetrag: 0,

    // Werte Zeitwertskonto
    aktives_ziel: "auszeit",
    aktive_optionen: {
        urlaub: true,
        ueberstunden: true,
        sparen_monat: false,
        sparen_jahr: true
    },
    zielkapital: null,
    gehalt_prozent: 100,
    wochenstunden: 40,

    auszeit_in_jahre: 3,
    ziel_in_monate: null,
    auszeit_monate: 12,
    ende_auszeit_monat: null,
    ende_auszeit_jahr: null,

    teilzeit_in_jahre: 3,
    teilzeit_dauer: 12,
    teilzeit_stunden: 20,
    ende_teilzeit_monat: null,
    ende_teilzeit_jahr: null,

    urlaub_jahr: 5,
    urlaub_jahr_inital: 5,
    urlaub_jahr_max: 10,
    wert_urlaubstag: null,
    summe_urlaubstage: 0,

    ueberstunden_monat: 10,
    ueberstunden_monat_inital: 10,
    ueberstunden_monat_max: 32,
    ueberstunden_switch: false,
    wert_ueberstunde: null,
    summe_ueberstunden: 0,

    sparen_gehalt: 100,
    sparen_gehalt_max: null,
    summe_monat: 0,
    // gespartes netto
    summe_nMonat: 0,
    // effektiv gezahlt
    summe_nettoEinzahlungMonat: 0,

    sparen_jahr: 500,
    sparen_jahr_max: null,
    sparrate_jahr_max: 1,
    sparrate_jahr_inital: 0.5,
    summe_sonderzahlung: 0,
    // gespartes netto
    summe_nSonderzahlung: 0,
    // effektiv gezahlt
    summe_nettoSonderzahlungJahr: 0,
    netto_zahlen_sonderzahlungJahr: 0,

    netto_monat: 0,
    netto_jahr: 0,
    netto_sJahr: 0,
    netto_sMonat: 0,
    netto_diff_monat: 0,
    netto_diff_jahr: 0,
    netto_zahlen_monat: 0,

    zuschuss_ag: 0,
    gehaltssteigerung: 0,
    years: [],
    jahr: null,
    monat: null,

    wunsch_rentenalter: 63,
    renteneintritt_jahr: null,
    rentenaustritt_jahr: null,
    rentenaustritt_monat: null,
    wunsch_rentenalter_min_init: 55,
    wunsch_rentenalter_min: null,

    geschlecht: "männlich",
    zuschuss_arbeitgeber: 0,

    gia_alter: 63,
    ende_gia_monat: null,
    ende_gia_jahr: null,
    sterbetafel: "DAV2004R",
    zusatzrente: 1000,
    rendite_ueberschuss_rente: 0,

    details: {
        input: 0,
        output: 0
    },

    lebenserwartung: null,
    renteneintritt: null,

    theme: {
        options: {
            ueberstunden: null,
            urlaub: null

        }
    },

    JAHRESLOHN_BRUTTO: 0,
    LOHNSTEUER: 0,

    JAHRESLOHN_BRUTTO_SPAREN: 0,
    LOHNSTEUER_SPAREN: 0,
    SOLIDARITAETSZUSCHLAG_SPAREN: 0,
    KIRCHENSTEUER_SPAREN: 0,
    RENTENVERSICHERUNG_SPAREN: 0,
    KRANKENVERSICHERUNG_SPAREN: 0,
    PFLEGEVERSICHERUNG_SPAREN: 0,
    ARBEITSLOSENVERSICHERUNG_SPAREN: 0,

    zusatz_optionen: true,

    kapital: 0,
    kapital_notwendig: 0,
    rentendauer: 0,
    anzeige_prozent: 0,
    ziel_prozent: 0,
    rendite: 2,
    rendite_gewinn: 0,
    rendite_gewinn_rente: 0,
    summe_arbeitgeber: 0,
    steuerersparnis: 0,
    keychange: 1,

    colorbar: {
        green: 5,
        brown: 5,
        yellow: 5,
        orange: 5,
        red: 5,
        purple: 5,
        blue: 5,
        lightblue: 5
    }
}
