<template>
  <section class="section">

    <PdfDownload ref="pdf" />
    <div class="container is-medium">
      <div class="columns is-3 is-multiline step-container">
        <div class="column is-12-touch is-8-desktop">
          <div class="part-left">
            <ResultTouch />
            <div class="container">
              <div class="is-relative subline">
                <Arrow />
                <span v-html="returnHtml(txtString.step5.headline) || ''">
                  <!-- Headline left -->
                </span>
              </div>
            </div>
            <div
              class="container"
              v-if="
                (getState.aktives_ziel === 'rente' ||
                  getState.aktives_ziel === 'auszeit' ||
                  getState.aktives_ziel === 'teilzeit') &&
                getState.theme.options.urlaub
              "
            >
              <Slider
                :setup="slider1"
                ref="slider"
                :key="getState.keychange + 'slide1'"
              />
              <div
                v-html="returnHtml(txtString.step5.left.slider1) || ''"
                class="description_slider"
              >
                <!-- Urlaubstage -->
              </div>
            </div>
            <div
              class="container"
              v-if="
                (getState.aktives_ziel === 'rente' ||
                  getState.aktives_ziel === 'auszeit' ||
                  getState.aktives_ziel === 'teilzeit') &&
                getState.theme.options.ueberstunden
              "
            >
              <Slider
                :setup="slider2"
                ref="slider"
                :key="getState.keychange + 'slide2'"
              />
              <div
                v-html="returnHtml(txtString.step5.left.slider2) || ''"
                class="description_slider"
              >
                <!-- Überstunden -->
              </div>
            </div>
            <div
              class="container"
              v-if="getState.aktives_ziel === 'geldImAlter'"
            >
              <Slider
                :setup="slider5"
                ref="slider"
                :key="getState.keychange + 'slide5'"
              />
              <div
                v-html="returnHtml(txtString.step5.left.slider3) || ''"
                class="description_slider"
              >
                <!-- Rendite -->
              </div>
            </div>
            <div
              class="container"
              v-if="getState.aktives_ziel === 'geldImAlter' &&  getState.theme.options.arbeitgeberzuschuss"
            >
              <Slider
                :setup="slider6"
                ref="slider"
                :key="getState.keychange + 'slide6'"
              />
              <div
                v-html="returnHtml(txtString.step5.left.slider4) || ''"
                class="description_slider"
              >
                <!-- Arbeitgeberzuschuss -->
              </div>
            </div>
            <div v-if="getState.theme.options.sparen_monat" class="container">
              <Slider
                :setup="slider3"
                ref="slider"
                :key="getState.keychange + 'slide3'"
              />
              <div
                v-html="returnHtml(txtString.step5.left.slider5) || ''"
                class="description_slider"
              >
                <!-- Sparen Monat -->
              </div>
            </div>
            <div v-if="getState.theme.options.sparen_jahr" class="container">
              <Slider
                :setup="slider4"
                ref="slider"
                :key="getState.keychange + 'slide4'"
              />
              <div
                v-html="returnHtml(txtString.step5.left.slider6) || ''"
                class="description_slider"
              >
                <!-- Sparen Jahr -->
              </div>
            </div>
            <div class="level is-mobile">
              <div
                v-html="returnHtml(txtString.general.button.back) || ''"
                class="level-left button back"
                v-on:click="navigate(3)"
              >
                <!-- Button Zurück -->
              </div>
              <div
                v-html="returnHtml(txtString.general.button.reset) || ''"
                class="level-left button reset"
                v-on:click="navigate(1)"
              >
                <!-- Button Zurücksetzen -->
              </div>
            </div>
            <div class="details-wrap is-hidden-desktop">
              <div class="details">
                <div
                  v-html="returnHtml(txtString.step5.right.button.pdf) || ''"
                  class="forward_bg"
                  @click="createPdf"
                >
                  <!-- PDF -->
                </div>
                <div
                  v-html="
                    returnHtml(txtString.step5.right.button.details) || ''
                  "
                  class="forward_bg"
                  @click="modal"
                >
                  <!-- DETAILS  -->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="column is-12-touch is-4-desktop is-relative is-hidden-touch"
        >
          <div class="result is-relative">
            <div class="wrap">
              <div class="is-relative title-right">
                <Arrow />
                <span v-html="returnHtml(txtString.step5.right.headline) || ''">
                  <!-- Headline right -->
                </span>
              </div>

              <div class="selection has-text-centered">
                <div class="columns is-mobile">
                  <div
                    v-if="getState.aktives_ziel === 'auszeit'"
                    class="column"
                  >
                    <div class="infobox">
                      <Textinput
                        :setup="textinput1"
                        :key="getState.keychange + 'input1'"
                      />
                    </div>
                    <div class="description">
                      <span
                        v-html="
                          returnHtml(txtString.step5.right.textinput1) || ''
                        "
                      >
                        <!-- Auszeit - Jahre bis Ziel -->
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="getState.aktives_ziel === 'auszeit'"
                    class="column"
                  >
                    <div class="infobox">
                      <Textinput
                        :setup="textinput2"
                        :key="getState.keychange + 'input2'"
                      />
                    </div>
                    <div class="description">
                      <span
                        v-html="
                          returnHtml(txtString.step5.right.textinput2) || ''
                        "
                      >
                        <!-- Auszeit - Dauer -->
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="getState.aktives_ziel === 'teilzeit'"
                    class="column"
                  >
                    <div class="infobox">
                      <Textinput
                        :setup="textinput4"
                        :key="getState.keychange + 'input4'"
                      />
                    </div>
                    <div class="description">
                      <span
                        v-html="
                          returnHtml(txtString.step5.right.textinput3) || ''
                        "
                      >
                        <!-- Teilzeit - Jahre bis Ziel -->
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="getState.aktives_ziel === 'teilzeit'"
                    class="column"
                  >
                    <div class="infobox">
                      <Textinput
                        :setup="textinput5"
                        :key="getState.keychange + 'input5'"
                      />
                    </div>
                    <div class="description">
                      <span
                        v-html="
                          returnHtml(txtString.step5.right.textinput4) || ''
                        "
                      >
                        <!-- Teilzeit - Dauer -->
                      </span>
                    </div>
                  </div>
                  <div v-if="getState.aktives_ziel === 'rente'" class="column">
                    <div class="infobox">
                      <Textinput
                        :setup="textinput3"
                        :key="getState.keychange + 'input3'"
                      />
                    </div>
                    <div class="description">
                      <span
                        v-html="
                          returnHtml(txtString.step5.right.textinput5) || ''
                        "
                      >
                        <!-- rentennahe Auszeit - Wunschalter  -->
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="getState.aktives_ziel === 'geldImAlter'"
                    class="column"
                  >
                    <div class="infobox">
                      <Textinput
                        :setup="textinput6"
                        :key="getState.keychange + 'input6'"
                      />
                    </div>
                    <div class="description">
                      <span
                        v-html="
                          returnHtml(txtString.step5.right.textinput6) || ''
                        "
                      >
                        <!-- Geld im Alter - Rentenalter -->
                      </span>
                    </div>
                  </div>
                  <div
                    v-if="getState.aktives_ziel === 'geldImAlter'"
                    class="column"
                  >
                    <div class="infobox">
                      <Textinput
                        :setup="textinput7"
                        :key="getState.keychange + 'input7'"
                      />
                    </div>
                    <div class="description">
                      <span
                        v-html="
                          returnHtml(txtString.step5.right.textinput7) || ''
                        "
                      >
                        <!-- Geld im Alter - Zusatzrente/Monat -->
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <ResultDesktop />
            </div>
            <div>
              <div class="details">
                <div
                  class="forward_bg"
                  @click="createPdf"
                  v-html="returnHtml(txtString.step5.right.button.pdf) || ''"
                >
                  <!-- PDF -->
                </div>
                <div class="forward_bg" @click="modal">DETAILS</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-3 advice">
        <div
          class="column is-full is-4-desktop is-offset-8-desktop"
          v-html="returnHtml(txtString.step5.right.note) || ''"
        >
          <!-- Vermerk -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapState } from "vuex";
import Slider from "../components/Slider.vue";
import Textinput from "../components/Textinput.vue";
import ResultDesktop from "../components/Result-Desktop.vue";
import ResultTouch from "../components/Result-Touch.vue";
import PdfDownload from "../pdf/PdfDownload.vue";
import Arrow from "../svg/Arrow.vue";


import { bus } from "../main";
export default {
  name: "Step5",
  components: {
    Slider,
    Textinput,
    ResultDesktop,
    ResultTouch,
    PdfDownload,
    Arrow
   
  },
  data: () => {
    return {
      bonus: false,
      slider1: {
        min: 0,
        max: urlaub_max ? urlaub_max : 10,
        step: 1,
        start: 5,
        id_tag: "urlaub_jahr",
        calculate_on_input: true,
      },
      slider2: {
        min: 0,
        max: ueberstunden_max ? ueberstunden_max : 40,
        step: 1,
        start: 10,
        id_tag: "ueberstunden_monat",
        calculate_on_input: true,
      },
      slider3: {
        min: 0,
        step: 10,
        start: 350,
        id_tag: "sparen_gehalt",
        unit: "€",
        double_input: false,
      },
      slider4: {
        min: 0.0,
        step: 100,
        start: 3500,
        id_tag: "sparen_jahr",
        unit: "€",
        double_input: true,
      },
      slider5: {
        min: 0,
        max: 8,
        step: 0.5,
        start: 2,
        id_tag: "rendite",
        unit: "%",
        calculate_on_input: true,
      },
      slider6: {
        min: 0,
        max: 2000,
        step: 50,
        start: 200,
        id_tag: "zuschuss_arbeitgeber",
        unit: "€",
        calculate_on_input: true,
      },
      textinput1: {
        id_tag: "auszeit_in_jahre",
        placeholder: "3",
        calculate_on_input: true,
        inputtype: "number",
        min: 1,
        max: 10,
        calculate_on_input: true,
      },
      textinput2: {
        id_tag: "auszeit_monate",
        placeholder: "1",
        inputtype: "number",
        min: 1,
        max: 60,
        calculate_on_input: true,
      },
      textinput3: {
        id_tag: "wunsch_rentenalter",
        placeholder: "60",
        inputtype: "number",
        min: 50,
        max: 66,
        calculate_on_input: true,
      },
      textinput4: {
        id_tag: "teilzeit_in_jahre",
        placeholder: "2",
        inputtype: "number",
        min: 1,
        max: 30,
        calculate_on_input: true,
      },
      textinput5: {
        id_tag: "teilzeit_dauer",
        placeholder: "12",
        inputtype: "number",
        min: 1,
        max: 72,
        calculate_on_input: true,
      },
      textinput6: {
        id_tag: "gia_alter",
        placeholder: "63",
        inputtype: "number",
        min: 63,
        max: 70,
        calculate_on_input: true,
      },
      textinput7: {
        min: 100,
        max: 6000,
        step: 100,
        unit: "€",
        id_tag: "zusatzrente",
        calculate_on_input: true,
      },
      anrede: null,
    };
  },
  methods: {
    navigate: function (number) {
      // var option = this.getState.zusatz_optionen === true ? 3 : 2;
      // this.$emit("navigate", option);
      if (number === 1) {
        location.reload();
      } else {
        this.$emit("navigate", 3);
        document.getElementById("app").scrollIntoView();
      }
    },
    modal: function () {
      document.getElementById("modal").classList.add("is-active");
      // document.querySelector("html").style.overflow="hidden";
    },
    addCommas: function (nStr) {
      nStr += "";
      var x = nStr.split(".");
      var x1 = x[0];
      var x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "." + "$2");
      }
      return x1 + x2;
    },
    createPdf: function () {
      this.$refs.pdf.generateReport();
    },
  },
  computed: {
    getState() {
      return this.$store.getters.getState;
    },
  },
  created() {},

  mounted() {
    bus.$on("toggle_bonus", () => {
      this.bonus = !this.bonus;
    });
  },
};
</script>
<style scoped lang="scss">
.st0 {
  fill: #afb2bc;
}
.st1 {
  fill: #40464f;
}
.section {
  padding: 0;
  .title {
    display: flex;
    justify-content: center;
    width: auto;
    margin-bottom: 3rem;
    font-size: 2rem;
    font-weight: 600;
  }
  .column {
    span {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
}
.infobox {
  // @media screen and (min-width: 1024px) and (max-width: 1216px) {
  //   width: max-content;
  //   min-width: -webkit-fill-available;
  // }
}
</style>
