<template>
  <svg
    class="pfeil"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 83.9 24.7"
    aria-labelledby="iconName"
    style="enable-background: new 0 0 83.9 24.7"
    xml:space="preserve"
    role="presentation"
    x="0px"
    y="0px"
  >
    <title id="ArrowBlack" lang="en">icon</title>
    <path
     
      :style="style"
      d="M71.9,0.6c-0.4-0.4-1-0.4-1.4,0s-0.4,1,0,1.4l9.3,9.3H1.5c-0.6,0-1,0.4-1,1s0.4,1,1,1h78.2l-9.3,9.3
	c-0.4,0.4-0.4,1,0,1.4c0.2,0.2,0.4,0.3,0.7,0.3s0.5-0.1,0.7-0.3l11.3-11.3c0.2-0.2,0.2-0.5,0-0.7L71.9,0.6z"
    />

    <slot />
  </svg>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ArrowBlack",
  methods: {},
  computed: {
    getState() {
      return this.$store.getters.getState;
    },
    style() {
      return "fill:#2B2B2B";
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
