<template>
  <div id="app">
    <div class="container is-small"></div>
    <div class="container app-wrap">
      <div class="content-tab" id="step1">
        <Step1 @navigate="navigate"></Step1>
      </div>
      <div class="content-tab" id="step2">
        <Step2 @navigate="navigate"></Step2>
      </div>
      <div class="content-tab" id="step3">
        <Step3 @navigate="navigate"></Step3>
      </div>
      <div class="content-tab" id="step4">
        <Step4 @navigate="navigate"></Step4>
      </div>
      <div class="content-tab" id="step5">
        <Step5 @navigate="navigate"></Step5>
      </div>
    </div>
    <Details />
  </div>
</template>

<script>
import { bus } from "./main";
import Step1 from "./steps/Step1.vue";
import Step2 from "./steps/Step2.vue";
import Step3 from "./steps/Step3.vue";
import Step4 from "./steps/Step4.vue";
import Step5 from "./steps/Step5.vue";
import Details from "./components/Details.vue";
import axios from "axios";
import { mapActions } from "vuex";
export default {
  name: "calculator",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
    Details,
  },
  data: () => {
    return {
      bonus: true,
      active_step: 0,
      total_steps: 5,
      anrede: null,
      logo: null,
    };
  },
  methods: {
    openTab: function (tabNumber) {
      var i, x;

      x = document.getElementsByClassName("content-tab");
      for (i = 0; i < x.length; i++) {
        x[i].style.display = "none";
      }

      document.querySelector(`#step${tabNumber}`).style.display = "block";
      bus.$emit("calibrate_slider");
      if (tabNumber === 5) {
        this.$store.commit("init_values", { calctype: "bare" });
        this.$store.commit("calc_sparrate", { calctype: "sparrate" });
        this.$store.commit("init_values", { calctype: "sMonat" });
        this.$store.commit("init_values", { calctype: "sJahr" });
        this.$store.commit("colorbar", {});
      }
    },
    navigate: function (next_step) {
      // this.active_step = next_step;
      this.openTab(next_step);
    },
    injectThemesettings: function (idName, settingValue) {
      this.$store.commit("change_value", {
        id: idName,
        value: settingValue,
      });
    },
  },
  created() {
    this.$store.commit("change_value", {
      id: "theme",
      value: {
        color: {
          arrow: arrow_color,
          button: button_color,
        },
        options: {
          auszeit: auszeit,
          rente: rente,
          teilzeit: teilzeit,
          geldImAlter: geldImAlter,
          urlaub: urlaub,
          arbeitgeberzuschuss: arbeitgeberzuschuss,
          ueberstunden: ueberstunden,
          sparen_monat: sparen_monat,
          sparen_jahr: sparen_jahr,
        },
        logo: logo,
        arrow_url: pdf_arrow_url,
        anrede: anrede,
        contact: {
          phone: telephone,
          mail: email,
        },
      },
    });

    this.injectThemesettings("ueberstunden_monat_max", ueberstunden_max);
    this.injectThemesettings("urlaub_jahr_max", urlaub_max);
    this.injectThemesettings("rendite", rendite_inital);
    this.injectThemesettings("bundesland",bundesland_inital)
    this.injectThemesettings("ueberstunden_switch",ueberstunden_switch)
    this.injectThemesettings("sparen_monat_bool",sparen_monat_bool)
    this.injectThemesettings("sparen_monat_prozent",sparen_monat_prozent)
    // this.injectThemesettings("rendite_gia", rendite_inital_gia);
  },
  mounted() {
    bus.$on("toggle_bonus", () => {
      this.bonus = !this.bonus;
    });
    this.openTab(1);
    this.$store.commit("init_time", { calctype: "bare" });
    this.$store.commit("init_values", { calctype: "bare" });
  },
};
</script>

<style lang="scss">
 


</style>
