<template>
  <div>
    <div class="container">
      <div class="progress">
        <div class="percent">
          <svg id="circle-desk">
            <circle cx="80" cy="80" r="80"></circle>
            <circle
              :style="circleColor"
              id="circle2"
              cx="80"
              cy="80"
              r="80"
              stroke-linecap="round"
            ></circle>
          </svg>
          <div class="icon">
            <component v-bind:is="zielName" />
          </div>
        </div>
      </div>
    </div>
    <div class="container">  
       <div class="result_switch has-text-centered">
        <div v-if="getState.ziel_prozent < 100"
         v-html="returnHtml(txtString.step5.result.isLess) || ''">
          <!-- is less then 100% -->
        </div>
        <div v-else-if="getState.ziel_prozent === 100"
        v-html="returnHtml(txtString.step5.result.equals) || ''">
          <!-- equals 100% -->
        </div>
        <div v-else-if="getState.ziel_prozent > 100 && (getState.aktives_ziel === 'rente' || getState.aktives_ziel === 'geldImAlter')"
        v-html="returnHtml(txtString.step5.result.isGreater.pension) || ''">
         <!-- rentennahe Auszeiz & Rente - greater then 100% -->
        </div>
        <div v-else-if="getState.ziel_prozent > 100 && (getState.aktives_ziel === 'auszeit' || getState.aktives_ziel === 'teilzeit')"
        v-html="returnHtml(txtString.step5.result.isGreater.time) || ''" >
            <!-- Auszeit & Teilzeit - greater then 100% -->
        </div>          
        
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { bus } from "../main";
export default {
  name: "Results-Desktop",

  data: () => {
    return {
       anrede: null
    };
  },
  methods: {
    modal: function () {
      document.getElementById("modal").classList.add("is-active");
      // document.querySelector("html").style.overflow="hidden";
    },
    addCommas: function (nStr) {
      nStr += "";
      var x = nStr.split(".");
      var x1 = x[0];
      var x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "." + "$2");
      }
      return x1 + x2;
    },
  },
  computed: {
    getState() {
      return this.$store.getters.getState;
    },
    zielName(){
      var ziel=this.getState.aktives_ziel;
      var capital=ziel.charAt(0).toUpperCase() + ziel.slice(1);
      return capital
    },
    circleColor(){
      return "stroke: "+this.getState.theme.color.arrow+";"
    }
  },

  mounted() {},
    created(){
        
       }
};
</script>
<style scoped lang="scss">
.st0 {
  fill: #afb2bc;
}
.st1 {
  fill: #40464f;
}
.section {
  .title {
    display: flex;
    justify-content: center;
    width: auto;
    margin-bottom: 3rem;
    font-size: 2rem;
    font-weight: 600;
  }
  .column {
    span {
      font-size: 1.5rem;
      font-weight: 600;
    }
  }
}
</style>
