export function calcyAnim() {
    const calcy = document.getElementsByClassName('calcy')[0];
    var prevScrollpos = window.pageYOffset;

    window.onscroll = function () {
        var currentScrollPos = window.pageYOffset;
        if (prevScrollpos > currentScrollPos) {
            if (window.innerWidth < 1024) {
                calcy.style.bottom = "0rem";
            } else {
                calcy.style.bottom = "-4rem";
            }
        } else {
            calcy.style.bottom = "-14rem";
        }
        prevScrollpos = currentScrollPos;
    }
};