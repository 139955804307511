<template>
  <section class="section">
    <div class="container is-medium">
      <div class="columns is-3 is-multiline step-container">
        <div class="column is-12-touch is-8-desktop">
          <div class="part-left">
            <div class="subline card-title">
              <Arrow />
             <span v-html="returnHtml(txtString.general.headline) || ''">
               <!-- Headline general -->
             </span>
            </div>
            <div class="">
              <div class="ziele">
                <div class="columns is-centered is-mobile is-multiline">
                  <div
                    class="column is-relative is-full-mobile is-6-tablet is-6-desktop is-6-widescreen is-5-fullhd"
                    v-on:click="onSelect(ziel1.name)"
                  >
                    <Ziel
                      v-if="getState.theme.options.auszeit"
                      :setup="ziel1"
                      ref="ziel"
                    />
                  </div>
                  <div
                    class="column is-relative is-full-mobile is-6-tablet is-6-desktop is-6-widescreen is-5-fullhd"
                    v-on:click="onSelect(ziel2.name)"
                  >
                    <Ziel
                      v-if="getState.theme.options.rente"
                      :setup="ziel2"
                      ref="ziel"
                    />
                  </div>
                  <div
                    class="column is-relative is-full-mobile is-6-tablet is-6-desktop is-6-widescreen is-5-fullhd"
                    v-on:click="onSelect(ziel3.name)"
                  >
                    <Ziel
                      v-if="getState.theme.options.teilzeit"
                      :setup="ziel3"
                      ref="ziel"
                    />
                  </div>
                  <div
                    v-on:click="onSelect(ziel4.name)"
                    class="column is-relative is-full-mobile is-6-tablet is-6-desktop is-6-widescreen is-5-fullhd"
                  >
                    <Ziel
                      v-if="getState.theme.options.geldImAlter"
                      :setup="ziel4"
                      ref="ziel"
                    />
                  </div>
                </div>
              </div>
              <div v-html="returnHtml(txtString.step1.left.info) || ''" class="description_ziele">
                <!-- Infotext -->
              </div>
              
            </div>

            <div class="level is-mobile">
              <div class="level-item"></div>
              <div class="level-right" v-on:click="navigate(2)"></div>
            </div>
          </div>
        </div>
        <div class="column is-12-touch is-4-desktop">
          <div class="part-right">
            <div class="is-relative">
              <ArrowBlack />
              <span v-html="returnHtml(txtString.step1.right.headline) || ''">
                <!-- Headline right -->
              </span>
              <p v-html="returnHtml(txtString.step1.right.text) || ''">
                <!-- Text right -->
              </p>

            </div>           
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>

import Ziel from "../components/Ziel.vue";
// import SwitchUeberstunden from "../components/SwitchUeberstunden.vue";
import Arrow from "../svg/Arrow.vue";
import ArrowBlack from "../svg/ArrowBlack.vue";
import { mapState } from "vuex";
export default {
  name: "Step1",
  components: {
    Ziel,
    Arrow,
    ArrowBlack
    // SwitchUeberstunden,
  },
  data: () => {
    return {
     
      ziel1: {
        name: "auszeit",
        titel: "Flexzeit",
      },
      ziel2: {
        name: "rente",
        titel: "Rentennahe Auszeit",
      },
      ziel3: {
        name: "teilzeit",
        titel: "Teilzeit",
      },
      ziel4: {
        name: "geldImAlter",
        titel: "Mehr Geld im Alter",
      },
      switch1:{
        id_tag:"theme.options.ueberstunden"
      },
      anrede: null,
      logo: null,
    };
  },
  methods: {

    onSelect: function (evt) {
      this.$store.commit("change_value", {
        id: "aktives_ziel",
        value: evt,
      });
      this.navigate(2);
    },

    navigate: function (direction) {
      document.getElementById("app").scrollIntoView();
      this.$emit("navigate", direction);
    },
  },

  computed: {
    getState() {
      return this.$store.getters.getState;
      },
  },

  created() {  
  },
};
</script>
<style scoped lang="scss">
.section {
  padding: 0;
}
</style>
