import bulmaCollapsible from "@creativebulma/bulma-collapsible";

export function Navigation() {

    var burger = document.querySelector(".burger");

    if(burger !== null ){
         var brand = document.querySelector(".navbar-brand");
        var nav = document.querySelector("#primary-menu");
        burger.addEventListener("click", function () {
            burger.classList.toggle("is-active");
            nav.classList.toggle("is-active");
            brand.classList.toggle("is-open");
        });
        bulmaCollapsible.attach(".is-collapsible-menu");


        let navbar = document.querySelector(".navbar");

        function Sticky(){
            document.documentElement.scrollTop > 0
            ? navbar.classList.add("sticky")
            : navbar.classList.remove("sticky");
        }

        window.addEventListener("load", Sticky);
        window.addEventListener("scroll", Sticky);
    }

}
