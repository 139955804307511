<template>
<div class="pdf-header">
  <div>
      <img
          class="company-logo"
          :src="getState.theme.logo.url"
          :alt="getState.theme.logo.alt"
      />
  </div>
    </div>
</template>

<script>

import { mapState } from "vuex";
export default {
  name: "PdfDownloadHeader", 
  computed: {
    getState() {
      return this.$store.getters.getState;
    },
  },
};

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

</style>
